import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from 'src/app/_shared/configs.model';
import * as fromVatReports from '../reducers/vat-report.reducer';

export const selectState = createFeatureSelector<fromVatReports.State>(fromVatReports.featureKey);

export const selectSettings = createSelector(selectState, fromVatReports.getSettings);

export const selectAll = createSelector(selectState, fromVatReports.selectAll);

export const selectIsLoaded = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADED
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADING
);

export const selectTotalElementsCount = createSelector(
  selectState,
  fromVatReports.getTotalElementsCount
);

export const selectPageIndex = createSelector(selectState, fromVatReports.getPageIndex);

export const selectEntities = createSelector(selectState, fromVatReports.selectEntities);

export const selectById = ({ uuid }) =>
  createSelector(selectEntities, (entities) => entities[uuid]);

export const selectInfA = createSelector(selectState, fromVatReports.getInfA);
export const selectInfB = createSelector(selectState, fromVatReports.getInfB);
export const selectDownloadUrl = createSelector(selectState, fromVatReports.getDownloadUrl);
